import React, { useState, useEffect } from "react";

const adjectives = await (await fetch("data/adjectives.json")).json();
const animals = await (await fetch("data/animals.json")).json()


const generateNickname = () => {
  const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const animal = animals[Math.floor(Math.random() * animals.length)];
  const randomNb = Math.floor(Math.random() * 100) + 1;

  return adjective + animal + (randomNb < 10 ? "0" + randomNb : randomNb);
};

const NicknamePage = () => {
  const [nickname, setNickname] = useState("");

  useEffect(() => {
    setNickname(generateNickname());
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      <h1 className="text-4xl font-bold">
        <strong>{nickname}</strong>
      </h1>
    </div>
  );
};

export default NicknamePage;
